import React from 'react'
import Layout from '../layouts'
import { graphql, Link } from 'gatsby'
// import { Container } from "reactstrap"
// import SEO from "../components/seo"
import marked from 'marked'

class BlogTemplate extends React.Component {
	render() {
		const { post } = this.props.data
		const { previous, next } = this.props.pageContext

		return (
			<Layout head={{ title: post.title }}>
				<div
					style={{
						backgroundImage: `url(${post.mainImage.asset.fluid.src})`,
						height: 480,
						width: '100%',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				/>
				<div class="page-headline">
					<div class="container">
						<div class="section-heading text-center">
							<h6 class="font-weight-bold text-uppercase text-white-50 flair">
								Blog
							</h6>

							<h1>
								<strong>{post.title}</strong>
								<br />
							</h1>
							<span class="small">
								<i class="fa fa-calendar-o pr-1" />
								{post.publishedAt ? post.publishedAt : post._createdAt}
							</span>
						</div>
					</div>
				</div>
				<div className="page-content">
					<div className="container py-5">
						<div class="row justify-content-center">
							<div class="col-md-9">
								<div
									dangerouslySetInnerHTML={{
										__html: marked(post.body),
									}}
								/>
							</div>
						</div>
						<div class="blog-nav d-none">
							<div class="row">
								<div class="col-md-6 text-md-left">
									{previous ? (
										<div>
											<span class="font-weight-bold text-uppercase text-muted d-block small">
												Previous
											</span>
											<Link to={previous.slug.current}>{previous.title}</Link>
										</div>
									) : null}
								</div>
								<div class="col-md-6 text-md-right">
									{next ? (
										<div>
											<span class="font-weight-bold text-uppercase text-muted d-block small">
												next
											</span>
											<Link to={next.slug.current}>{next.title}</Link>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default BlogTemplate

export const blogQuery = graphql`
	query BlogPostTemplateQuery($id: String!) {
		post: sanityPost(id: { eq: $id }) {
			id
			mainImage {
				asset {
					fluid {
						src
					}
				}
			}
			_createdAt(formatString: "MMMM DD, YYYY")
			publishedAt(formatString: "MMMM DD, YYYY")
			body
			title
		}
	}
`
